import flatpickr from "flatpickr";
import { French } from "flatpickr/dist/l10n/fr.js"

const initFlatpickr = () => {
  flatpickr.localize(French);

  flatpickr('.flatpickr-datetime', {
    enableTime: true,
    dateFormat: "Y-m-d H:i",
    altInput: true,
    altFormat: "l d F Y à H:i",
  });
}

export { initFlatpickr };
