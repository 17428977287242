const acceptMusic     = document.getElementById("user_accept_music")
const acceptSmoker    = document.getElementById("user_accept_smoker")
const musicForm = document.querySelector(".music-form")
const smokerForm = document.querySelector(".smoker-form")

if (acceptMusic) {
  acceptMusic.addEventListener("change", (event) => {
    Rails.fire(musicForm, "submit")
  })
}

if (acceptSmoker) {
  acceptSmoker.addEventListener("change", (event) => {
    Rails.fire(smokerForm, "submit")
  })
}
