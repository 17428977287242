import Swal from 'sweetalert2'

const sweetAlertQuestionBtns = document.querySelectorAll('.btn-sweet-alert-question')
const authenticityToken      = document.getElementById('authenticity_token').value

if (sweetAlertQuestionBtns) {
  sweetAlertQuestionBtns.forEach((sweetAlertQuestionBtn) => {
    sweetAlertQuestionBtn.addEventListener("click", (event) => {
      event.preventDefault()
      Swal.fire({
        title: sweetAlertQuestionBtn.dataset.question,
        type: 'question',
        showCancelButton: true,
        confirmButtonColor: '#FFD700',
        cancelButtonColor: '#3E3E3E',
        confirmButtonText: 'Oui',
        cancelButtonText: 'Annuler'
      }).then((result) => {
        if (result.value) {
          fetch(sweetAlertQuestionBtn.dataset.url, {
            method: sweetAlertQuestionBtn.dataset.urlMethod,
            headers: {
              'X-Requested-With': 'XMLHttpRequest',
              'X-CSRF-Token': authenticityToken
            },
            body: {
              credentials: 'same-origin'
            }
          }).then(() => {
            Swal.fire({
                title: sweetAlertQuestionBtn.dataset.confirmation,
                type: 'success',
                confirmButtonColor: '#FFD700'
              }).then(() => {
              if ( sweetAlertQuestionBtn.dataset.redirection ) {
                document.location.href = sweetAlertQuestionBtn.dataset.redirection
              } else {
                document.location.reload(true)
              }
            })
          })
        }
      })
    })
  })
}

const userNoSeats = document.querySelector('.user_no_seats');

if (userNoSeats) {
  userNoSeats.addEventListener("click", (event) => {
    event.preventDefault()
    Swal.fire({
      title: 'Infos manquantes',
      html:
      `Précisez le modèle de votre voiture et le nombre de places disponibles sur votre
      <a href="/profile">tableau de bord</a>
      pour pouvoir créer une annonce`,
      type: 'warning',
      showCancelButton: false,
      confirmButtonColor: '#FFD700',
      cancelButtonColor: '#3E3E3E',
      confirmButtonText: 'Ok',
    })
  })
}

const passengerPopUp = document.querySelector('.popup-show-passenger');

if (passengerPopUp) {
  const passengerPopUpContent = passengerPopUp.dataset.content;
  console.log(passengerPopUpContent)
  Swal.fire({
    html: `<div class ="markdown" data-content="${passengerPopUpContent}" data-toggle="modal"></div>
            <p>Tu trouveras plus d’informations sur les conditions d’utilisation ici :
              <div class ="link-legal" data-target="#legalmodal" data-toggle="modal">
                « mentions légales et CGU »
              </div>
            </p>`,
    type: 'warning',
    showCancelButton: false,
    confirmButtonColor: '#FFD700',
    cancelButtonColor: '#3E3E3E',
    confirmButtonText: 'j’ai compris',
    customClass: {content: 'swal-left-align-bullet-point'},
  }).then(() => {
    fetch(passengerPopUp.dataset.url, {
      method: passengerPopUp.dataset.urlMethod,
      headers: {
        'X-Requested-With': 'XMLHttpRequest',
        'X-CSRF-Token': authenticityToken
      },
      body: {
        credentials: 'same-origin'
      }
    })
  })
}

const driverPopUp = document.querySelector('.popup-show-driver');

if (driverPopUp) {
  const driverPopUpContent = driverPopUp.dataset.content;
  Swal.fire({
    html: `<div class ="markdown" data-content="${driverPopUpContent}" data-toggle="modal"></div>
            <p>Tu trouveras plus d’informations sur les conditions d’utilisation ici :
              <div class ="link-legal" data-target="#legalmodal" data-toggle="modal">
                « mentions légales et CGU »
              </div>
            </p>`,
    type: 'warning',
    showCancelButton: false,
    confirmButtonColor: '#FFD700',
    cancelButtonColor: '#3E3E3E',
    confirmButtonText: 'j’ai compris',
    customClass: {content: 'swal-left-align-bullet-point'},
  }).then(() => {
    fetch(driverPopUp.dataset.url, {
      method: driverPopUp.dataset.urlMethod,
      headers: {
        'X-Requested-With': 'XMLHttpRequest',
        'X-CSRF-Token': authenticityToken
      },
      body: {
        credentials: 'same-origin'
      }
    })
  })
}
