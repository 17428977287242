const infiniteScrolling = document.getElementById('infinite-scrolling')

if ( infiniteScrolling ) {
  window.addEventListener('scroll', event => {
    const nextPage     = document.querySelector('.pagination .page-link[rel="next"]')
    const scrollTop    = window.scrollY
    const heightMargin = 60
    const topPosition  = document.body.offsetHeight - window.innerHeight - heightMargin
    if ( nextPage && scrollTop > topPosition ) {
      const nextPageUrl = nextPage.getAttribute('href')
      infiniteScrolling.innerHTML ='<div class="spinner-border" role="status"><span class="sr-only">Loading...</span></div>'
      Rails.ajax({
        url: nextPageUrl,
        type: "GET",
      })
    }
  })
}
