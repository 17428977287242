import $ from 'jquery';
import 'select2/dist/js/select2.full';

const initSelect2 = () => {
  $(document).ready(function() {
    $('.select2').select2({
      width: '100%'
    });

    $('.select2#club_id').on('select2:select', (_event) => {
      const ridesSearch = document.getElementById('rides-search');
      ridesSearch.submit();
  });
  });
}

export { initSelect2 }
