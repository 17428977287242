import MarkdownIt from 'markdown-it';

const initMarkdown = () => {
  const markdownsContents = document.querySelectorAll('.markdown');

  markdownsContents.forEach((markdownContent) => {
    console.log(markdownContent);
    const markdown            = new MarkdownIt();
    markdownContent.innerHTML = markdown.render(markdownContent.dataset.content);
  });
};

export { initMarkdown };
