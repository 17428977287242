import places from 'places.js';

const searchAddressInput          = document.getElementById('search_address');
const rideDepartureAddressInput   = document.getElementById('ride_departure_address');
const rideDestinationAddressInput = document.getElementById('ride_destination_address');
const clubAddress                 = document.getElementById('club_address');
const algoliaEnvVariable          = document.getElementById('algolia-env-variable');

document.addEventListener('DOMContentLoaded', () => {
  if (searchAddressInput) {
    initAutocompletePassengerDepartureAddress(searchAddressInput)
    const closeBtn = document.querySelector('.ap-input-icon')

    if ( searchAddressInput.value != "" ) closeBtn.style.display = "initial"
    closeBtn.addEventListener('click', () => refreshSearch())
  }

  if (rideDepartureAddressInput)   initAutocompleteDepartureAddress(rideDepartureAddressInput)
  if (rideDestinationAddressInput) initAutocompleteDestinationAddress(rideDestinationAddressInput)
  if (clubAddress)                 initAutocompleteDestinationAddress(clubAddress)
})

const refreshSearch = () => {
  const searchForm = document.querySelector(".search-rides-form")
  if ( searchForm ) {
    const searchLat = document.getElementById('search_latitude')
    const searchLng = document.getElementById('search_longitude')
    searchLat.value = ""
    searchLng.value = ""
    searchForm.submit()
  }
}

const initAutocompletePassengerDepartureAddress = (input) => {
  const placesAutocompleteAddress = places({
    container: input,
    templates: {
      value: suggestion => suggestion.name
    }
  }).configure({ type: 'address' })

  placesAutocompleteAddress.on('change', e => {
    const searchLat = document.getElementById('search_latitude');
    const searchLng = document.getElementById('search_longitude');

    searchLat.value = e.suggestion.latlng.lat
    searchLng.value = e.suggestion.latlng.lng
  })
}

const initAutocompleteDepartureAddress = (input) => {
  const placesAutocompleteDepartureAddress = places({
    appId:     algoliaEnvVariable.dataset.appId,
    apiKey:    algoliaEnvVariable.dataset.apiKey,
    container: input,
    templates: {
      value: suggestion => {
        console.log(suggestion)
        return `${suggestion.name}, ${suggestion.city}`
      }
    }
  }).configure({ type: 'address' })

  placesAutocompleteDepartureAddress.on('change', e => {
    const departureLat  = document.getElementById('ride_departure_latitude')
    const departureLng  = document.getElementById('ride_departure_longitude')
    const departureCity = document.getElementById('ride_departure_city')

    departureLat.value  = e.suggestion.latlng.lat
    departureLng.value  = e.suggestion.latlng.lng
    departureCity.value = e.suggestion.city
  })
}

const initAutocompleteDestinationAddress = (input) => {
  const placesAutocompleteDestinationAddress = places({
    appId:     algoliaEnvVariable.dataset.appId,
    apiKey:    algoliaEnvVariable.dataset.apiKey,
    container: input,
    templates: {
      value: suggestion => `${suggestion.name}, ${suggestion.city}`
    }
  }).configure({ type: 'address' })

  placesAutocompleteDestinationAddress.on('change', e => {
    let destinationLat      = document.getElementById('ride_destination_latitude');
    let destinationLng      = document.getElementById('ride_destination_longitude');
    let destinationCity     = document.getElementById('ride_destination_city');
    let destinationPostcode = document.getElementById('ride_destination_postcode');

    destinationLat      = destinationLat || document.querySelector('.ride_destination_latitude');
    destinationLng      = destinationLng || document.querySelector('.ride_destination_longitude');
    destinationCity     = destinationCity || document.querySelector('.ride_destination_city');
    destinationPostcode = destinationPostcode || document.querySelector('.ride_destination_postcode');

    if (destinationLat && destinationLng) {
      destinationLat.value      = e.suggestion.latlng.lat;
      destinationLng.value      = e.suggestion.latlng.lng;
    }

    if (destinationCity) {
      destinationCity.value     = e.suggestion.city;
    }

    if (destinationPostcode) {
      destinationPostcode.value = e.suggestion.postcode;
    }
  })
}
