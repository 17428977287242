const initTooltip = () => {
  if ("ontouchstart" in window || window.DocumentTouch && document instanceof DocumentTouch) {
    // No tooltips on touch screens
  } else {
    $(function () {
      $('[data-toggle="tooltip"]').tooltip()
    })
  }
}

export { initTooltip }
