import piexif from 'piexifjs'

const profilePicInput = document.getElementById("user_photo")

if ( profilePicInput ) {
  const profilePicLabel               = document.getElementById("user_photo_label")
  const profilePicDeleteIconContainer = document.getElementById("profile-pic-delete-icon-container")
  profilePicInput.addEventListener('change', event => {
    const file   = profilePicInput.files[0]
    const reader = new FileReader();
    reader.onloadend = () => {
      const originalImage = reader.result
      try {
        const imageExif = piexif.load(originalImage)
        const orientation   = imageExif["0th"][piexif.ImageIFD.Orientation]
        resetOrientation(originalImage, orientation, (resetBase64Image) => {
          profilePicLabel.style.backgroundImage = `url('${resetBase64Image}')`
        })
      }
      catch(_) {
        profilePicLabel.style.backgroundImage = `url('${originalImage}')`
      }
    }
    if (file) {
      reader.readAsDataURL(file)
      profilePicDeleteIconContainer.classList.remove('d-none')
    }
  })

  profilePicDeleteIconContainer.addEventListener('click', event => {
    const imgPath                         = profilePicLabel.dataset.profilePicUrl
    profilePicLabel.style.backgroundImage = `url('${imgPath}')`
    profilePicInput.value                 = ''
    profilePicDeleteIconContainer.classList.add('d-none')
  })


}

const resetOrientation = (srcBase64, srcOrientation, callback) => {
  let img = new Image();

  img.onload = function() {
    let width  = img.width,
        height = img.height,
        canvas = document.createElement('canvas'),
        ctx    = canvas.getContext("2d");

    // set proper canvas dimensions before transform & export
    if (4 < srcOrientation && srcOrientation < 9) {
      canvas.width = height;
      canvas.height = width;
    } else {
      canvas.width = width;
      canvas.height = height;
    }

    // transform context before drawing image
    switch (srcOrientation) {
      case 2: ctx.transform(-1, 0, 0, 1, width, 0); break;
      case 3: ctx.transform(-1, 0, 0, -1, width, height); break;
      case 4: ctx.transform(1, 0, 0, -1, 0, height); break;
      case 5: ctx.transform(0, 1, 1, 0, 0, 0); break;
      case 6: ctx.transform(0, 1, -1, 0, height, 0); break;
      case 7: ctx.transform(0, -1, -1, 0, height, width); break;
      case 8: ctx.transform(0, -1, 1, 0, 0, width); break;
      default: break;
    }

    // draw image
    ctx.drawImage(img, 0, 0);

    // export base64
    callback(canvas.toDataURL());
  }
  img.src = srcBase64;
}
