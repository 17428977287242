import Swal from 'sweetalert2';

const departments      = document.querySelectorAll('#map .department');
const departmentSelect = document.getElementById('department_select_input');

departments.forEach((department) => {
  department.addEventListener('click', (event) => {
    document.getElementById('department_id').value = department.id;
    document.getElementById('department_select').submit();
  })
});

if (departmentSelect) {
  $('#department_select_input').on('select2:select', function (event) {
    document.querySelector('body').style.cursor = 'wait';
    document.getElementById(departmentSelect.value).classList.add('active');
    document.getElementById('department_id').value = departmentSelect.value;
    document.getElementById('department_select').submit();
  });
}

const disabledDepartements = document.querySelectorAll('#map path.disabled');

disabledDepartements.forEach((department) => {
  department.addEventListener("click", (event) => {
    event.preventDefault()
    const tchaTchaText = `La préfecture des Pyrénées-Atlantiques a un partenariat avec l'application
      <a href="https://tchatcha.app/">Tcha-Tcha</a>`;
    const unavailableText = "L'application n'est pas disponible pour le moment pour ce département"
    const reason = department.dataset.reason;

    Swal.fire({
      title: reason == 'tcha-tcha' ? "Partenariat avec Tcha-Tcha" : "Indisponible pour le moment",
      html: reason == 'tcha-tcha' ? tchaTchaText : unavailableText,
      type: reason == 'tcha-tcha' ? 'info' : 'warning',
      showCancelButton: false,
      confirmButtonColor: '#FFD700',
      cancelButtonColor: '#3E3E3E',
      confirmButtonText: 'Ok',
    })
  })
});
