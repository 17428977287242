//= require sweetalert2
//= require jquery
//= require jquery_ujs
//= require turbolinks
//= require jquery.turbolinks
//= require bootstrap-sprockets
//= require sweet-alert-confirm

import "bootstrap";

import "flatpickr/dist/flatpickr.css";
import 'select2/dist/css/select2.css';

import "../components/autocomplete";
import "../components/sweet_alert";
import "../components/switch";
import "../components/menu";
import "../components/profile_picture";
import "../components/infinite_scrolling";
import "../components/department_map_select";

import { initFlatpickr } from "../plugins/init_flatpickr";
import { initSelect2 } from "../plugins/init_select2";
import { initTooltip } from "../plugins/init_tooltip";
import { initMarkdown } from "../plugins/init_markdown";

initMarkdown();
initFlatpickr();
initTooltip();
initSelect2();
