const inputMenu  = document.querySelector('#menu-toggle input[type=checkbox]')
const burgerMenu = document.getElementById ('burger-menu')
const crossMenu  = document.getElementById ('cross-menu')


if (inputMenu) {
  inputMenu.addEventListener("click", (event) => {
    burgerMenu.classList.toggle("d-none")
    crossMenu.classList.toggle("d-none")
  })
}
